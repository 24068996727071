import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";

import FileUpload from "../fileUpload/FileUpload";

import "./PostForm.scss";

const ADDPOST = loader("../../graphql/post/ADDPOST.gql");
const EDITPOST = loader("../../graphql/post/EDITPOST.gql");

const PostForm = ({ mutation, data, setOpen }) => {
  // State
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
  });

  // Querys & Mutations
  const [addPost] = useMutation(ADDPOST, {
    errorPolicy: "all",
  });

  const [editPost] = useMutation(EDITPOST, {
    errorPolicy: "all",
  });

  // Did mount
  useEffect(() => {
    data && setFormData({ ...data });
  }, []);

  const { title, description, image } = formData;

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    // Create error arry
    let err = [];
    Object.keys(formData).map(key => {
      if (!formData[key]) {
        err.push(key);
      }
    });

    if (err.length >= 1) {
      toast.error(`Es Fehlt noch: ${err.join(", ")}`, {
        position: "bottom-center",
      });
    } else {
      let sendData = {};
      let image = "";
      Object.keys(formData).map(key => {
        if (key === "image") {
          image = formData[key];
        } else {
          sendData[key] = formData[key];
        }
      });

      if (mutation === "ADD") {
        addPost({ variables: { ...sendData, image } });
        toast.success("Post hinzugefügt", {
          position: "bottom-center",
        });
      } else {
        editPost({
          variables: {
            ...sendData,
            id: parseInt(formData.id),
          },
        });
        toast.success("Post geändert", {
          position: "bottom-center",
        });
      }
    }
    setFormData({
      title: "",
      description: "",
      image: "",
    });

    setOpen && setOpen(false);
  };

  return (
    <div id="post-form">
      <form className="new-post-form" onSubmit={e => onSubmit(e)}>
        <div className="imgage flex-container ">
          <Popup
            trigger={
              <a className="btn btn-secondary min-width">
                <i className="fas fa-cloud-upload-alt"></i> Bild hochladen
              </a>
            }
            position="right center">
            <div>
              {mutation === "ADD" ? (
                <FileUpload
                  formData={formData}
                  setFormData={setFormData}
                  mutation={ADDPOST}
                />
              ) : (
                <FileUpload
                  formData={formData}
                  id={parseInt(data.id)}
                  mutation={EDITPOST}
                />
              )}
            </div>
          </Popup>
          {image && mutation === "ADD" && (
            <input
              type="text"
              name="image"
              id="new-post-image"
              value={image.name}
              onChange={e => onChange(e)}
              readOnly
              required
            />
          )}
        </div>
        <div>
          <label htmlFor="new-post-title">Titel:</label>
          <input
            type="text"
            name="title"
            id="new-post-title"
            value={title}
            onChange={e => onChange(e)}
            required
          />
        </div>
        <div>
          <label htmlFor="new-post-description">Mitteilung:</label>
          <textarea
            type="text"
            name="description"
            id="new-post-description"
            value={description}
            onChange={e => onChange(e)}
            required
          />
        </div>
        <div className="flex-container">
          <button type="submit" className="btn btn-secondary">
            Speichern
          </button>
          <button
            type="reset"
            className="btn btn-primary"
            onClick={() =>
              setFormData({ title: "", description: "", image: "" })
            }>
            Löschen
          </button>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
