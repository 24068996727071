import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// private Routes
import PrivateRoute from "./PrivateRoute";

// Public Pages
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import Events from "../pages/events/Events";
import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
// Intern Pages
import Members from "../pages/members/Members";
import NewPost from "../pages/newPost/NewPost";
import Profile from "../pages/profile/Profile";

const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/about" component={About} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/intra/profile/:id" component={Profile} />
        <PrivateRoute exact path="/intra/newpost" component={NewPost} />
        <PrivateRoute exact path="/intra/members" component={Members} />
        <Route path="/" component={Home} />
      </Switch>
    </Fragment>
  );
};

export default Routes;
