import React from "react";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";

import SocialMedia from "../../components/socialMedia/SocialMedia";

import T1 from "../../assets/topicals/topical-1.jpg";

import "./Login.scss";

const Login = ({ history }) => {
  let email;
  let password;

  // GQL
  const LOGIN = loader("../../graphql/auth/LOGIN.gql");
  // GQL mutation
  const [login, data] = useMutation(LOGIN, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  if (data.error) {
    toast.error(data.error.message, {
      position: "bottom-center",
    });
  }

  if (data.data) {
    email = "";
    password = "";
    sessionStorage.setItem("logout", Date.now() + 3600000);
    sessionStorage.setItem("role", data.data.login.role);
    history.push("/intra/profile/" + data.data.login.id);
  }

  return (
    <div>
      <section id="login" className="flex-columns">
        <div className="row">
          <div className="column">
            <div className="column-1">
              <img src={T1} alt="topical" />
            </div>
          </div>
          <div className="column">
            <div className="column-2 bg-dark">
              <h1>Login</h1>
              <h3>Interner Bereich</h3>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  login({
                    variables: { email: email.value, password: password.value },
                  });
                }}>
                <div className="login-form-group">
                  <label htmlFor="login-email">Email</label>
                  <input
                    ref={e => {
                      email = e;
                    }}
                    type="email"
                    name="email"
                    id="login-email"
                    required
                  />
                </div>
                <div className="login-form-group">
                  <label htmlFor="login-password">Password</label>
                  <input
                    ref={p => {
                      password = p;
                    }}
                    type="password"
                    name="password"
                    id="login-password"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-light">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <SocialMedia />
    </div>
  );
};

export default Login;
