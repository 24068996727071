import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/react-hooks";

import "./FileUpload.scss";

const FileUpload = ({ id, setFormData, formData, mutation }) => {
  const [uploadFile] = useMutation(mutation);

  const onDrop = useCallback(
    acceptedFiles => {
      const image = acceptedFiles[0];

      if (setFormData) {
        // Add new
        setFormData({ ...formData, image });
      } else if (id) {
        // Edit Admin
        uploadFile({
          variables: { image, id: id },
          onCompleted: () => {},
        });
      } else {
        // Edit Owner
        uploadFile({
          variables: { image },
          onCompleted: () => {},
        });
      }
    },
    [uploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div
      id="dropzone"
      {...getRootProps()}
      className={`dropzone ${isDragActive && "isActive"}`}>
      <input
        {...getInputProps()}
        accept={["image/jpeg", "image/png", "image/bmp"]}
        max-size="5"
      />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </div>
  );
};
export default FileUpload;
