import React from "react";

import "./SocialMedia.scss";

const SocialMedia = () => {
  return (
    <section id="social-media" className="icon bg-light">
      <div className="social-media-title">
        <h2>Besucht uns auf Facebook und Instagram</h2>
      </div>
      <div className="flex-items">
        <div>
          <a
            href="https://facebook.com/avcuriensis"
            target="_blank"
            rel="noreferrer">
            <i className="fab fa-facebook fa-3x"></i>
          </a>
          <p>AV Curiensis</p>
        </div>
        <div>
          <a
            href="https://www.instagram.com/curiensis/"
            target="_blank"
            rel="noreferrer">
            <i className="fab fa-instagram fa-3x"></i>
          </a>
          <p>@Curiensis</p>
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
