import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";

// Import images
import Logo from "../../assets/header/wappenschild.png";
import Zirkel from "../../assets/header/zirkel_weiss.png";

// Import style
import "./Header.scss";

// Import fetch schema
const LOGOUT = loader("../../graphql/auth/LOGOUT.gql");

// Start function
const Header = ({ changeQuote, location }) => {
  // State
  const [showMenu, setShowMenu] = useState(false);
  const [headerClass, setHeaderClass] = useState("hero");
  const [navBg, setNavBg] = useState("nav-bg top");
  const [navTitle, setNavTitle] = useState("nav-title top");

  // GQL mutation
  const [logout] = useMutation(LOGOUT, { fetchPolicy: "no-cache" });

  // Did mount
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > window.innerHeight / 2;
      if (show) {
        setNavBg("nav-bg");
        setNavTitle("nav-title");
      } else {
        setNavBg("nav-bg top");
        setNavTitle("nav-title top");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Header big on home, small on other
  useEffect(() => {
    if (location.pathname === "/") {
      setHeaderClass("hero ");
    } else {
      setHeaderClass("hero hero-small");
      setNavBg("nav-bg");
      setNavTitle("nav-title");
    }
  });

  const onClickHeandler = e => {
    setShowMenu(!showMenu);
    changeQuote();
  };

  const logoutOnClick = () => {
    logout();
    sessionStorage.clear();
    onClickHeandler();
  };

  const links = [
    { name: "Home", path: "/" },
    { name: "Über uns", path: "/about" },
    { name: "Anlässe", path: "/events" },
    { name: "Kontakt", path: "/contact" },
  ];

  return (
    <header className={headerClass} id="header">
      <div className="navbar" id="navbar">
        <div className={navBg} />
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo" />
              <h1 className={navTitle}>AV Curiensis</h1>
            </Link>
          </div>
          <div className="menu-wrap">
            <input
              type="checkbox"
              className="toggler"
              id="toggler"
              checked={showMenu}
              onChange={() => setShowMenu(!showMenu)}
            />
            <div className="hamburger">
              <div></div>
            </div>
            <nav className="menu">
              <div>
                <div>
                  <ul>
                    {[...links].map(e => {
                      return (
                        <li key={e.name}>
                          <Link to={e.path} onClick={onClickHeandler}>
                            {e.name}
                          </Link>
                        </li>
                      );
                    })}
                    {sessionStorage.getItem("role") === "ADMIN" && (
                      <li>
                        <Link to="/intra/newpost" onClick={onClickHeandler}>
                          <i className="fas fa-comment"></i>{" "}
                          <span className="lg-screen">Neuer Post</span>
                        </Link>
                      </li>
                    )}
                    {sessionStorage.getItem("role") === "USER" ||
                    sessionStorage.getItem("role") === "ADMIN" ? (
                      <Fragment>
                        <li>
                          <Link to="/intra/members" onClick={onClickHeandler}>
                            <i className="fas fa-users"></i>{" "}
                            <span className="lg-screen">Mitglieder</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/intra/profile/${sessionStorage.getItem(
                              "id"
                            )}`}
                            onClick={onClickHeandler}>
                            <i className="fas fa-user"></i>{" "}
                            <span className="lg-screen">Profil</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/" onClick={logoutOnClick}>
                            <i className="fas fa-sign-out-alt "></i>{" "}
                            <span className="lg-screen">Logout</span>
                          </Link>
                        </li>
                      </Fragment>
                    ) : (
                      <li key="login">
                        <Link to="/login" onClick={onClickHeandler}>
                          Login
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className="content">
        <img src={Zirkel} alt="Zirkel" />
        <h1>AV Curiensis</h1>
        <p>Während, neben, nach dem Studium</p>
        <h4>seit 1868 in Chur</h4>
      </div>
    </header>
  );
};

export default withRouter(Header);
