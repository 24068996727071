const labels = {
  firstname: "Vorname *",
  lastname: "Nachname *",
  vulgo: "Vulgo *",
  address: "Strasse *",
  zip: "PLZ *",
  city: "Ort *",
  birthday: "Geburtstag",
  phone: "Telefon",
  mobile: "Mobile",
  email: "E-Mail *",
  email2: "2. E-Mail",
  password: "Neues Password",
  password2: "Neues Password bestätigen",
  school: "Schule",
  fos: "Studiengang",
  degree: "Abschluss",
  entryCU: "Eintritt Curiensis (Jahr)",
  entryStV: "Eintritt Schw. StV. (Jahr)",
  rank: "Rang",
  role: "Rolle",
};

export default labels;
