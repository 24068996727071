import React from "react";

import "./Member.scss";

const Member = ({ data }) => {
  return (
    <a href={`mailto:${data.rank.toLowerCase()}@avcuriensis.ch`}>
      <div>
        {data.image && (
          <img src={`/${data.image.path}`} alt={data.image.filename} />
        )}
        <h4>
          {data.firstname} {data.lastname} v/o {data.vulgo}
        </h4>
        <p>
          <strong>
            {data.rank.charAt(0) + data.rank.slice(1).toLowerCase()}
          </strong>
        </p>
        <p>{data.rank.toLowerCase()}@avcuriensis.ch</p>
      </div>
    </a>
  );
};

export default Member;
