const labels = {
  firstname: "",
  lastname: "",
  vulgo: "",
  address: "",
  zip: "",
  city: "",
  fos: "",
  degree: "",
  school: "",
  email: "",
  email2: "",
  phone: "",
  mobile: "",
  entryCU: "",
  entryStV: "",
  birthday: "",
  password: "",
  password2: "",
  image: null,
  quote: "",
  role: "USER",
  rank: "AKTIVITAS",
};

export default labels;
