import React from "react";

import SocialMedia from "../../components/socialMedia/SocialMedia";

import T1 from "../../assets/topicals/topical-1.jpg";
import T4 from "../../assets/topicals/topical-4.jpg";

import "./Contact.scss";

const Contakt = () => {
  return (
    <div>
      <div id="contact">
        <section id="contact-aktivitas" className="flex-columns">
          <div className="row">
            <div className="column">
              <div className="column-1">
                <img src={T4} alt="" />
              </div>
            </div>
            <div className="column">
              <div className="column-2 bg-primary">
                <div className="post-adress">
                  <h1>Kontak Aktivitas</h1>

                  <h3>
                    <i className="far fa-address-card"></i> Postanschrift
                  </h3>
                  <p>AV Curiensis</p>
                  <p>Alte Schanfiggerstrasse 7</p>
                  <p>7000 Chur</p>
                </div>

                <div className="email">
                  <h3>
                    <i className="far fa-envelope"></i> E-Mail
                  </h3>
                  <p>
                    <a href="mailto:info@avcuriensis.ch">info@avcuriensis.ch</a>
                  </p>
                </div>

                <div className="bank-account">
                  <h3>
                    <i className="fas fa-university"></i> Bankverbindung
                  </h3>
                  <p>UBS Chur</p>
                  <p>IBAN: CH43 0020 8208 1127 1540 D</p>
                  <p>AV Curiensis, 7000 Chur</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SocialMedia />

        <section
          id="contact-ahah"
          className="contact-ahah flex-columns flex-reverse">
          <div className="row">
            <div className="column">
              <div className="column-1">
                <img src={T1} alt="" />
              </div>
            </div>
            <div className="column">
              <div className="column-2 bg-secondary">
                <div className="post-adress">
                  <h1>Kontak Altherrenschaft</h1>

                  <h3>
                    <i className="far fa-address-card"></i> Postanschrift
                  </h3>
                  <p>Marco Süess v/o Caritas</p>
                  <p>Rüschen 2</p>
                  <p>9042 Speicher</p>
                </div>

                {/* // TODO: Caritas fragen mail  */}
                {/* <div className="email">
                  <h3>E-Mail</h3>
                  <p><a href="mailto:info@avcuriensis.ch">info@avcuriensis.ch</a></p>
                </div> */}

                <div className="bank-account">
                  <h3>
                    <i className="fas fa-university"></i> Bankverbindung
                  </h3>
                  <p>PostFinance</p>
                  <p>IBAN: CH22 0900 0000 7000 7387 3</p>
                  <p>Alt-Curiensis, Heiligkreuz (Mels)</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contakt;
