import React, { useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import CircularProgress from "@material-ui/core/CircularProgress";

import Event from "../../components/event/Event";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Zirkel from "../../assets/header/zirkel_weiss.png";

import EventForm from "../../components/event/EventForm";

import "./Events.scss";

// Import Fetch Schemas
const EVENTS = loader("../../graphql/event/EVENTS.gql");

const Events = () => {
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState({});

  const { loading, data } = useQuery(EVENTS);

  if (loading)
    return (
      <div className="center">
        <CircularProgress color="inherit" />
      </div>
    );
  if (data.error) {
    toast.error(data.error.message, {
      position: "bottom-center",
    });
  }

  const eventHandler = e => {
    setOpen(!open);
    setEvent(e);
  };

  const now = Date.now();

  let previousEvents = [];
  let upcomingEvents = [];

  data.events.map(e =>
    e.time >= now ? upcomingEvents.push(e) : previousEvents.push(e)
  );

  return (
    <div id="events">
      {data.events.length === 0 || previousEvents.length === 0 ? (
        <div id="events">
          <div className="bg-dark">
            <section className="events events flex-grid">
              <h1>Moment haben wir keine Anlässe</h1>
            </section>
          </div>
        </div>
      ) : (
        <div className="bg-dark">
          <section className="events events flex-grid">
            <header className="section-header">
              <h1>Zukünftige Anlässe</h1>
              <h3>Kommt vorbei</h3>
            </header>
            <div className="row">
              {upcomingEvents.map(e => (
                <Event key={e.id} data={e} eventHandler={eventHandler} />
              ))}
            </div>
          </section>
          <section className="events events flex-grid previousEvents">
            <header className="section-header">
              <h1>Vergangene Anlässe</h1>
            </header>
            <div className="row">
              {previousEvents.reverse().map(e => (
                <Event key={e.id} data={e} eventHandler={eventHandler} />
              ))}
            </div>
          </section>
        </div>
      )}

      {/* new event */}
      {sessionStorage.getItem("role") === "ADMIN" && !open && (
        <div className="center bg-light">
          <button
            className="btn btn-primary my-5"
            onClick={() => setOpen(true)}>
            Neues Event hintufügen
          </button>
        </div>
      )}

      {open && (
        <div className="flex-container bg-dark">
          {event.id ? (
            <EventForm
              event={event}
              mutation={"EDIT"}
              eventHandler={eventHandler}
            />
          ) : (
            <EventForm mutation={"ADD"} eventHandler={eventHandler} />
          )}
          <button
            className="btn btn-primary"
            onClick={() => {
              setOpen(false);
              setEvent({});
            }}>
            Schliessen
          </button>
        </div>
      )}

      <div className="events-download bg-secondary">
        <h1>Download</h1>
        <h4>Und nie mehr einen Anlass verpassen</h4>
        <a
          href="/pdf/SemesterprogrammHS20.pdf"
          target="_blank"
          rel="noreferrer">
          <div className="bg-primary">
            <img src={Zirkel} alt="Zirkel" />
            <h4>Semesterprogramm HS 2020</h4>
          </div>
        </a>
      </div>
      <SocialMedia />
    </div>
  );
};

export default Events;
