const rank = [
  "Aktivitas",
  "Senior",
  "Cosenior",
  "Aktuar",
  "Questor",
  "FM",
  "Revisor",
  "AHP",
  "AH-Kassier",
  "AH",
];

export default rank;
