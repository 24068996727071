const labels = {
  title: "Titel",
  location: "Restaurant",
  city: "Ort",
  type: "Art des Anlasses",
  outfit: "Tenue",
  time: "Zeit",
  date: "Datum",
};

export default labels;
