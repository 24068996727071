import React from "react";

import FlexColums from "../../components/flexColums/FlexColums";
import Komitee from "../../components/komitee/Komitee";
import SocialMedia from "../../components/socialMedia/SocialMedia";

import AboutText from "./aboutText";

const About = props => {
  return (
    <main id="about">
      {/* About text */}
      <FlexColums
        key={AboutText[0].id}
        data={AboutText[0]}
        btn={"false"}
        fallback={true}
      />

      {/* Komitee */}
      <Komitee />

      {/* About text teil 2*/}
      <FlexColums
        key={AboutText[1].id}
        data={AboutText[1]}
        btn={"false"}
        fallback2={true}
        reverse={true}
      />

      <SocialMedia />
    </main>
  );
};

export default About;
