import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { useQuery, gql } from "@apollo/client";
import { loader } from "graphql.macro";

import {
  // Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import UserForm from "../../components/user/UserForm";
import useStyles from "./material-style";

import "./Members.scss";

// Import Fetch Schemas
const USERS = loader("../../graphql/user/USERS.gql");

const Members = () => {
  // State
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState();

  // Query & Mutation
  const { loading, data, error } = useQuery(USERS);

  // Material ui
  const classes = useStyles();

  let admin = sessionStorage.getItem("role") === "ADMIN";

  const onClickHandler = e => {
    setUser(e);
    setOpen(!open);
  };

  if (loading)
    return (
      <div className="center">
        <CircularProgress color="inherit" />
      </div>
    );
  if (error) {
    console.error(error);
    return (
      <div className="center">
        <h2>Error</h2>
      </div>
    );
  }
  if (data.error) {
    toast.error(data.error.message, {
      position: "bottom-center",
    });
  }

  const [...users] = data.users;

  if (data) {
    return (
      <div id="members">
        <TableContainer component={Paper}>
          <Table className={classes.table + " table"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.head + " firstname"} align="left">
                  Vorname
                </TableCell>
                <TableCell className={classes.head + " lastname"} align="left">
                  Nachname
                </TableCell>
                <TableCell className={classes.head + " vulgo"} align="left">
                  Vulgo
                </TableCell>
                <TableCell className={classes.head + " address"} align="left">
                  Strasse
                </TableCell>
                <TableCell className={classes.head + " city"} align="left">
                  Ort
                </TableCell>
                <TableCell className={classes.head + " email"} align="left">
                  Mail
                </TableCell>
                <TableCell className={classes.head + " mobile"} align="left">
                  Mobile
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(row => (
                <TableRow
                  className={classes.row}
                  key={row.vulgo}
                  onClick={() => onClickHandler(row)}>
                  <TableCell
                    className={classes.tbody + " firstname"}
                    align="left">
                    {row.firstname}
                  </TableCell>
                  <TableCell
                    className={classes.tbody + " lastname"}
                    align="left">
                    {row.lastname}
                  </TableCell>
                  <TableCell className={classes.tbody + " vulgo"} align="left">
                    {row.vulgo}
                  </TableCell>
                  <TableCell
                    className={classes.tbody + " address"}
                    align="left">
                    {row.address}
                  </TableCell>
                  <TableCell className={classes.tbody + " city"} align="left">
                    {row.city}
                  </TableCell>
                  <TableCell className={classes.tbody + " email"} align="left">
                    {row.email}
                  </TableCell>
                  <TableCell className={classes.tbody + " mobile"} align="left">
                    {row.mobile}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Add New Curienser */}
        {admin && !open && (
          <button
            className="btn btn-secondary my-4"
            onClick={() => onClickHandler()}>
            Neuer Curienser hinzufügen
          </button>
        )}

        {/* FormField Overlay */}
        {open && (
          <Fragment>
            <div className="my-5 flex-container">
              {!user ? (
                <UserForm
                  mutation={"ADD"}
                  userHandler={onClickHandler}
                  admin={true}
                  setOpen={setOpen}
                />
              ) : (
                <UserForm
                  user={user}
                  mutation={"EDIT"}
                  userHandler={onClickHandler}
                  admin={true}
                  setOpen={setOpen}
                />
              )}

              <button
                className="btn btn-primary"
                onClick={() => setOpen(false)}>
                Schliessen
              </button>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
};

export default Members;
