import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { loader } from "graphql.macro";
import { setLogVerbosity, useMutation } from "@apollo/client";

import PostForm from "../../components/post/PostForm";
import Fallback from "../../assets/topicals/topical-1.jpg";
import Fallback2 from "../../assets/topicals/topical-2.jpg";

import "./FlexColums.scss";

const RMPOST = loader("../../graphql/post/RMPOST.gql");

const FlexColums = ({ data, btn, fallback, fallback2 }) => {
  const [open, setOpen] = useState(false);

  const [rmPost] = useMutation(RMPOST, {
    errorPolicy: "all",
  });

  const { id, title, description, image } = data;

  const onRemove = () => {
    if (window.confirm("Bist du sicher?")) {
      rmPost({ variables: { id: parseInt(id) } });
    }
  };

  console.log(open);

  return (
    <section
      id="topical"
      // Change left and right
      className={id % 2 === 0 ? "flex-columns flex-reverse" : "flex-columns"}>
      <div className="row">
        <div className="column">
          <div className="column-1">
            {fallback ? (
              // For About or no Image save
              <img src={Fallback} alt="Jubiläums-Foto" />
            ) : fallback2 ? (
              // For About
              <img src={Fallback2} alt="Jubiläums-Foto" />
            ) : (
              // Nomral
              image && <img src={image.path} alt="topical" />
            )}
          </div>
        </div>
        <div className="column">
          <div
            className={
              // Change left and right
              id % 2 === 0 ? "column-2 bg-secondary" : "column-2 bg-primary"
            }>
            <h1>{title}</h1>
            <p>{description}</p>
            {btn === true &&
              (sessionStorage.getItem("role") === "ADMIN" ? (
                // Show Buttons for Edit and Delete Post
                <div className="buttons">
                  <Link to="/events" className="btn btn-outline">
                    Zu den Anlässe
                  </Link>
                  <button
                    className="btn btn-light"
                    onClick={() => setOpen(!open)}>
                    Bearbeiten
                  </button>
                  <button className="btn btn-dark" onClick={() => onRemove()}>
                    Löschen
                  </button>
                </div>
              ) : (
                // With out Button
                <Link to="/events" className="btn btn-outline">
                  Zu den Anlässe
                </Link>
              ))}
          </div>
        </div>
      </div>
      {open && (
        <div className="container">
          <PostForm mutation={"EDIT"} data={data} setOpen={setOpen} />
          <button className="btn btn-primary" onClick={() => setOpen(false)}>
            Schliesen
          </button>
        </div>
      )}
    </section>
  );
};

export default withRouter(FlexColums);
