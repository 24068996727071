import React from "react";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./Quote.scss";

const QUOTES = loader("../../graphql/user/QUOTES.gql");

const Quote = () => {
  const { loading, error, data } = useQuery(QUOTES);
  if (loading)
    return (
      <div className="center">
        <CircularProgress color="inherit" />
      </div>
    );
  if (error) {
    toast.error(error.message, {
      position: "bottom-center",
    });
  }
  if (!data) return <div className="center" />;

  const quote = data.quotes[Math.floor(Math.random() * data.quotes.length)];
  return (
    <section id="quote" className="bg-light quote">
      <div className="container flex-quote">
        <div className="image">
          {quote?.image && (
            <img src={`/${quote.image.path}`} alt={quote.image.filename} />
          )}
        </div>
        <p>{quote.quote}</p>
        <p className="author">
          {quote.firstname} {quote.lastname} v/o {quote.vulgo}
        </p>
      </div>
    </section>
  );
};

export default Quote;
