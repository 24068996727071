const template = {
  title: "",
  location: "",
  city: "",
  type: "off",
  outfit: "cps",
  time: "",
  date: "",
};

export default template;
