import React from "react";
import { toast } from "react-toastify";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";

import UserForm from "../../components/user/UserForm";

// Stylingsheet
import "./Profile.scss";

// Import Fetch Schema
const USER = loader("../../graphql/user/USER.gql");

// Function Profile Page
const Profil = () => {
  // Querys & Mutations
  const { loading, error, data } = useQuery(USER, {
    fetchPolicy: "cache-and-network",
  });

  if (loading)
    return (
      <div className="center">
        <CircularProgress color="inherit" />
      </div>
    );
  if (error) {
    toast.error(error.message, {
      position: "bottom-center",
    });
  }
  if (!data)
    return (
      <div className="center">
        <h1>Server Error</h1>
      </div>
    );

  return (
    <div id="profile">
      <div className="container">
        <h1>Hallo {data.user.vulgo}</h1>
        <UserForm user={data.user} mutation={"EDIT"} owner={true} />
      </div>
    </div>
  );
};

export default Profil;
