import React from "react";
import "./Event.scss";

const parseTimetoReadable = timeUx => {
  const time = new Date(parseInt(timeUx));

  const hours = time.getHours < 10 ? `0${time.getHours()}` : time.getHours();
  const min =
    time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
  const day = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate();
  const month =
    time.getMonth() + 1 < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1;
  const year = time.getFullYear();

  return `${day}.${month}.${year}, ${hours}:${min} `;
};

const Event = ({ data, eventHandler }) => {
  const formatedTime = parseTimetoReadable(data.time);

  const onClick = () => {
    if (!eventHandler) {
      console.error("Error: eventHandler not available");
    } else {
      eventHandler(data);
    }
  };

  return (
    <div className="column event-grid">
      <div
        {...(sessionStorage.getItem("role") === "ADMIN" && {
          onClick: () => onClick(),
          className: "pointer",
        })}>
        <img src="/images/beer.jpg" alt="stamm" className="event-img" />
        <div className="event-title">
          <h3>{data.title}</h3>
          <p>
            Ort: {data.location}, {data.city}
          </p>
          <p>
            {data.type}, {data.outfit}
          </p>
        </div>
        <div className="event-date">
          <p>{formatedTime}</p>
        </div>
      </div>
    </div>
  );
};

export default Event;
