import React from "react";
import { toast } from "react-toastify";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";

// Componets
import FlexColums from "../../components/flexColums/FlexColums";
import SocialMedia from "../../components/socialMedia/SocialMedia";

// Fallback
import Fallback from "./fallback";

// Stylesheet
import "./Home.scss";

// Import Fetch Schema
const FEED = loader("../../graphql/post/FEED.gql");

// Function Page Home
const Home = () => {
  // Fetch data
  const { loading, error, data } = useQuery(FEED, {
    fetchPolicy: "cache-and-network",
  });

  if (loading)
    return (
      <div className="center">
        <CircularProgress color="inherit" />
      </div>
    );
  if (error) {
    toast.error(error.message, {
      position: "bottom-center",
    });
  }
  if (data.error) {
    toast.error(data.error.message, {
      position: "bottom-center",
    });
  }

  let { posts } = data.feed;
  posts = posts.slice().reverse();

  // const fallback = {
  //   id: 1,
  //   title: "Leider sind keine Post vorhanden",
  //   description:
  //     "Wenn du trozdem mehr erfahren möchtes, schaud dich auf der Seite um, oder besuch uns auf unseren Sozialen Medien. Die Links findest du unter diesem Post",
  // };

  return posts.length >= 1 ? (
    <main id="home">
      {/* New Topical */}
      <FlexColums key={posts[0].id} data={posts[0]} btn={true} />

      {/* Social Media Component */}
      <SocialMedia />

      {/* Older Topical */}
      {posts.slice(1, 5).map(o => (
        <FlexColums key={o.id} data={o} reverse={true} btn={true} />
      ))}
    </main>
  ) : (
    <main id="home">
      {/* Fallback */}
      <FlexColums key="fallback" data={Fallback} fallback={true} btn={true} />

      {/* Social Media Component */}
      <SocialMedia />
    </main>
  );
};

export default Home;
