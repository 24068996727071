import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";

// Imoport components
import FileUpload from "../fileUpload/FileUpload";
import Input from "./Input";

// Templates for labels user rank
import labels from "./labels";
import userTemplate from "./userTemplate";
import rank from "./rank";
import { validation, prepareSend } from "./validation";

// Import style
import "./UserForm.scss";

// Import fetch schemas
const USERS = loader("../../graphql/user/USERS.gql");
const EDITUSER = loader("../../graphql/user/EDITUSER.gql");
const ADDUSER = loader("../../graphql/user/ADDUSER.gql");

// Start function
const UserForm = ({ user, mutation, userHandler, admin, owner, setOpen }) => {
  // State
  const [showButton, setShowButton] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    password2: "",
    ...user,
  });
  const [fetchData, setFetchData] = useState({ ...formData });

  // Load template by
  useEffect(() => {
    if (!user) {
      setFormData({ ...userTemplate });
      setFetchData({ ...userTemplate });
    }
  }, []);

  // By changes, show buttons
  useEffect(() => {
    if (JSON.stringify(formData) === JSON.stringify(fetchData)) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  });

  // Querys & mutation
  const [addUser, addData] = useMutation(ADDUSER, {
    errorPolicy: "all",
    update(cache, { data }) {
      const newUser = data?.addUser;
      const existingUsers = cache.readQuery({
        query: USERS,
      });
      cache.writeQuery({
        query: USERS,
        data: {
          users: [...existingUsers?.users, newUser],
        },
      });
    },
  });
  const [editUser, editData] = useMutation(EDITUSER, {
    errorPolicy: "all",
  });

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    const validate = validation(formData);

    if (validate.length >= 1) {
      validate.map(err =>
        toast.error(err, {
          position: "bottom-center",
        })
      );
    } else {
      let sendData = prepareSend(formData, fetchData);

      // If admin did a change, send id in body
      admin &&
        mutation === "EDIT" &&
        (sendData = {
          ...sendData,
          id: formData.id,
        });

      // Send mutation
      mutation === "ADD"
        ? // Add
          addUser({ variables: { ...sendData } })
        : // Edit
          editUser({ variables: { ...sendData } });

      // Close input fielder
      setOpen && setOpen(false);
    }
  };

  const onClose = () => {
    setFormData({ ...fetchData });
    setOpen && setOpen(false);
  };

  // Mutation errors
  if (addData.error) {
    toast.error(addData.error.message, {
      position: "bottom-center",
    });
  } else if (editData.error) {
    toast.error(editData.error.message, {
      position: "bottom-center",
    });
  }

  const { image } = formData;

  return (
    <div id="form-field" key="form-field" className="container">
      {/* Image */}
      <div key="flex-item" className="flex-item profile-image">
        {image && mutation !== "ADD" && (
          <div className="image" key="img-div">
            <img key="img" src={`/${image.path}`} alt="Profile-Image" />
          </div>
        )}
        {/* Only admin and owner can change image */}
        {(admin || owner) && (
          <Fragment>
            <Popup
              trigger={
                <button key="upload" className="btn btn-secondary">
                  <i className="fas fa-cloud-upload-alt"></i> Bild hochladen
                </button>
              }
              position="right center">
              <div key="file-upload">
                <FileUpload
                  id={!owner && formData.id}
                  setFormData={mutation === "ADD" ? setFormData : null}
                  formData={mutation === "ADD" ? formData : null}
                  mutation={mutation === "ADD" ? ADDUSER : EDITUSER}
                />
              </div>
            </Popup>
            {/* By add new user, imagename in a textbox */}
            {image && mutation === "ADD" && (
              <input
                key="new-post-image"
                type="text"
                name="image"
                id="new-post-image"
                value={image.name}
                onChange={e => onChange(e)}
                readOnly
                required
              />
            )}
          </Fragment>
        )}
      </div>
      {/* Formfilds */}
      <form onSubmit={e => onSubmit(e)}>
        {/* Type text */}
        {Object.keys(formData).map(
          key =>
            (key === "firstname" ||
              key === "lastname" ||
              key === "vulgo" ||
              key === "address" ||
              key === "city" ||
              key === "fos" ||
              key === "degree" ||
              key === "school") && (
              <Input
                k={key}
                formData={formData}
                labels={labels}
                type={"text"}
                readOnly={!admin && !owner}
                onChange={onChange}
                required={key !== "fos" && key !== "degree" && key !== "school"}
              />
            )
        )}
        {/* Type number */}
        {Object.keys(formData).map(
          key =>
            (key === "zip" || key === "entryCU" || key === "entryStV") && (
              <Input
                k={key}
                formData={formData}
                labels={labels}
                type={"number"}
                readOnly={!admin && !owner}
                onChange={onChange}
                required={key === "zip"}
              />
            )
        )}
        {/* Type email */}
        {Object.keys(formData).map(
          key =>
            (key === "email" || key === "email2") && (
              <Input
                k={key}
                formData={formData}
                labels={labels}
                type={"email"}
                readOnly={!admin && !owner}
                onChange={onChange}
                required={key === "email"}
              />
            )
        )}
        {/* Type tel */}
        {Object.keys(formData).map(
          key =>
            (key === "phone" || key === "mobile") && (
              <Input
                k={key}
                formData={formData}
                labels={labels}
                type={"tel"}
                placeholder="z.B. 081 111 11 11"
                pattern="[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}"
                readOnly={!admin && !owner}
                onChange={onChange}
              />
            )
        )}
        {/* Birthday */}
        <Input
          k={"birthday"}
          formData={formData}
          labels={labels}
          type={"date"}
          readOnly={!admin && !owner}
          onChange={onChange}
        />
        {/* Password */}
        {(owner || mutation === "ADD") &&
          Object.keys(formData).map(
            key =>
              (key === "password" || key === "password2") && (
                <Input
                  k={key}
                  formData={formData}
                  labels={labels}
                  type={"password"}
                  readOnly={!admin && !owner}
                  onChange={onChange}
                  required={mutation === "ADD"}
                />
              )
          )}
        {/* Admin area */}
        {admin && (
          <Fragment>
            <div key="rank-div" id="rank-div">
              <label htmlFor="rank">Rang</label>
              <select
                type="rank"
                value={formData.rank}
                id="rank"
                key="rank"
                name="rank"
                onChange={e => onChange(e)}>
                {rank.map(r => (
                  <option key={r} value={r.toUpperCase()}>
                    {r}
                  </option>
                ))}
              </select>
            </div>
            <div key="role-div" id="role-div">
              <label htmlFor="role">Rolle</label>
              <select
                type="role"
                value={formData.role}
                id="role"
                key="role"
                name="role"
                onChange={e => onChange(e)}>
                <option value="USER">User</option>
                <option value="ADMIN">Admin</option>
              </select>
            </div>
          </Fragment>
        )}
        {/* Quote */}
        <div key="quote-div" id="quote-div">
          <label htmlFor="quote">Zitat</label>
          <textarea
            id="quote"
            key="quote"
            name="quote"
            rows="5"
            defaultValue={formData.quote}
            readOnly={!admin && !owner}
            onChange={e => onChange(e)}></textarea>
        </div>
        {/* Buttons */}
        {showButton && (
          <div id="buttons-div" className="buttons">
            <button type="submit" className="btn btn-secondary">
              {mutation === "ADD" ? "Hinzufügen" : "Speichern"}
            </button>
            <a className="btn btn-primary" onClick={() => onClose()}>
              Abbrechen
            </a>
          </div>
        )}
      </form>
    </div>
  );
};

export default UserForm;
