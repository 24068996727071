import React from "react";

const Input = ({
  k,
  formData,
  labels,
  type,
  readOnly,
  onChange,
  placeholder,
  pattern,
  required,
}) => (
  <div key={k + "-div"} id={k + "-div"}>
    <label key={k + "-label"} htmlFor={k}>
      {labels[k]}
    </label>
    <input
      key={k + "-input"}
      id={k}
      type={type}
      value={formData[k]}
      placeholder={placeholder}
      pattern={pattern}
      name={k}
      readOnly={readOnly}
      onChange={e => onChange(e)}
      required={required}
    />
  </div>
);

export default Input;
