import React, { useState, useEffect } from "react";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import eventTemplate from "./eventTemplate";
import labels from "./labels";

import "./EventForm.scss";

const EVENTS = loader("../../graphql/event/EVENTS.gql");
const EDITEVENT = loader("../../graphql/event/EDITEVENT.gql");
const ADDEVENT = loader("../../graphql/event/ADDEVENT.gql");
const RMEVENT = loader("../../graphql/event/RMEVENT.gql");

// Parse Time form UNIX Timestamp to String
const parseTimetoReadable = timeUx => {
  const time = new Date(parseInt(timeUx));

  const hours = time.getHours < 10 ? `0${time.getHours()}` : time.getHours();
  const min =
    time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
  const day = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate();
  const month =
    time.getMonth() + 1 < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1;
  const year = time.getFullYear();

  return {
    time: `${hours}:${min}`,
    date: `${year}-${month}-${day}`,
  };
};

// Parse Time form String to UNIX Timestamp
const parseTimeToSend = (timeString, dataString) => {
  const timeArray = timeString.split(":");
  return `${dataString}-${timeArray[0]}-${timeArray[1]}`;
};

const PostForm = ({ mutation, event, eventHandler }) => {
  // State
  const [formData, setFormData] = useState({ ...eventTemplate });

  // Querys & Mutations
  const [addEvent] = useMutation(ADDEVENT, {
    errorPolicy: "all",
    update(cache, { data }) {
      const newEvent = data?.addEvent;
      const existingEvents = cache.readQuery({
        query: EVENTS,
      });
      cache.writeQuery({
        query: EVENTS,
        data: {
          events: [...existingEvents?.events, newEvent],
        },
      });
    },
  });
  const [editEvent] = useMutation(EDITEVENT, {
    errorPolicy: "all",
  });
  const [rmEvent, rmData] = useMutation(RMEVENT, {
    errorPolicy: "all",
    update(cache, { data }) {
      const removedEvent = data?.removeEvent;
      const existingEvents = cache.readQuery({
        query: EVENTS,
      });
      const remainingEvents = existingEvents.events.filter(
        e => e.id != removedEvent.id
      );

      cache.writeQuery({
        query: EVENTS,
        data: {
          events: remainingEvents,
        },
      });
    },
  });

  useEffect(() => {
    if (event) {
      const e = { ...event };
      delete e.id;
      delete e.__typename;
      delete e.time;

      const formattedTime = parseTimetoReadable(event.time);
      setFormData({ ...e, ...formattedTime });
    }
  }, [event]);

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    // Save temporarily because error
    const tempData = formData;

    // Create error arry
    let err = [];
    Object.keys(formData).map(key => {
      if (!formData[key]) {
        err.push(key);
      }
    });

    if (err.length >= 1) {
      toast.error(`Es Fehlt noch: ${err.join(", ")}`, {
        position: "bottom-center",
      });
    } else {
      const time = parseTimeToSend(tempData.time, tempData.date);
      const sendData = { ...tempData, time };
      delete sendData.date;

      mutation === "ADD"
        ? addEvent({ variables: { ...sendData } })
        : editEvent({
            variables: {
              ...sendData,
              id: parseInt(event.id),
            },
          });
    }
    eventHandler("");
  };

  const onRemove = () => {
    if (window.confirm("Bist du sicher?")) {
      eventHandler("");
      rmEvent({ variables: { id: parseInt(event.id) } });
    }
  };

  const { type, outfit, time, date } = formData;

  return (
    <div id="event-form">
      <div className="container">
        <h1 className="my-2">
          {mutation === "ADD" ? "Neuer Anlsass hinzufügen" : "Anlass editieren"}
        </h1>
        <form onSubmit={e => onSubmit(e)}>
          {/* Title, Location, City */}
          {Object.keys(formData).map(
            key =>
              (key === "title" || key === "location" || key === "city") && (
                <div key={key}>
                  <label htmlFor={key}>{labels[key] + " *"}</label>
                  <input
                    value={formData[key]}
                    type="text"
                    name={key}
                    id={key}
                    onChange={e => onChange(e)}
                    required
                  />
                </div>
              )
          )}
          {/* Type */}
          <div key="type">
            <label htmlFor={type}>{labels.type + " *"}</label>
            <select
              value={formData.type}
              type="text"
              name="type"
              id="type"
              onChange={e => onChange(e)}
              required>
              <option value="off">off</option>
              <option value="h'off">h'off</option>
              <option value="inoff">inoff</option>
              <option value="inoff">spez</option>
            </select>
          </div>
          {/* Outfit */}
          <div key="outfit">
            <label htmlFor={outfit}>{labels.outfit + " *"}</label>
            <select
              value={formData.outfit}
              type="text"
              name="outfit"
              id="outfit"
              onChange={e => onChange(e)}
              required>
              <option value="cps">cps</option>
              <option value="h'off">h'off</option>
              <option value="cpns">cpns</option>
            </select>
          </div>
          {/* Time */}
          <div key="time">
            <label htmlFor={time}>{labels.time + " *"}</label>
            <input
              value={formData.time}
              type="time"
              name="time"
              id="time"
              onChange={e => onChange(e)}
              required
            />
          </div>
          {/* Date */}
          <div key="date">
            <label htmlFor={date}>{labels.date + " *"}</label>
            <input
              value={formData.date}
              type="date"
              name="date"
              id="date"
              onChange={e => onChange(e)}
              required
            />
          </div>
          {/* Buttons */}
          <div className="buttons">
            <button type="submit" className="btn btn-secondary">
              {mutation === "ADD" ? "Hinzufügen" : "Speichern"}
            </button>
            {mutation === "EDIT" && (
              <button className="btn btn-primary" onClick={() => onRemove()}>
                Löschen
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostForm;
