import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  table: {
    minWidth: 650,
  },
  head: {
    fontSize: "2rem",
    background: "#ddd",
  },
  tbody: {
    fontSize: "1.6rem",
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
}));

export default useStyles;
