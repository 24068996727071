import React, { useState, Fragment } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Routes
import Routes from "./routes/Routes";

// Components
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Quote from "./components/quote/Quote";

import "./scss/App.scss";

const App = () => {
  const [randomQuote, setRandomQuote] = useState(false);

  const changeQuote = () => {
    setRandomQuote(!randomQuote);
  };

  return (
    <Fragment>
      <BrowserRouter>
        <Header changeQuote={changeQuote} />
        <Quote />
        <Route component={Routes} />
        <Footer position="bottom-center" autoClose={2500} />
        <ToastContainer />
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
