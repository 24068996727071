export const validation = formData => {
  const { password, password2, entryCU, entryStV } = formData;

  let err = [];
  // Password min length
  password &&
    password.length < 4 &&
    err.push("Passwörter muss mindesten 4 Zeichen lang sein!");
  // Check if password was changed, it is the same
  password !== password2 && err.push("Passwörter stimmen nicht überein!");
  // Wrong year format
  entryCU &&
    entryCU.length !== 4 &&
    err.push(
      "Bitte gib dein Eintritts Jahr in der Curiensis wie folgt an: z.B. 2020"
    );
  entryStV &&
    entryStV.length !== 4 &&
    err.push(
      "Bitte gib dein Eintritts Jahr im Schw. StV. wie folgt an: z.B. 2020"
    );
  // Return with errors
  return err.length >= 1 && err;
  // Return without errors
  return null;
};

export const prepareSend = (formData, fetchData) => {
  let sendData = {};
  Object.keys(fetchData).map(key => {
    if (formData[key] !== fetchData[key]) {
      if (key === "zip") return (sendData[key] = parseInt(formData[key]));
      if (key === "image") return (sendData[key] = formData[key]);
      sendData[key] = formData[key];
    }
  });

  return sendData;
};
