import React from "react";

import PostForm from "../../components/post/PostForm";

import "./NewPost.scss";

const NewPost = () => {
  return (
    <main id="new-post">
      <div className="container">
        <h1 className="my-2">Neuer Post verfassen</h1>
        <PostForm mutation={"ADD"} />
      </div>
    </main>
  );
};

export default NewPost;
