const mockData = [
  {
    id: 1,
    title: "Über uns",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus repudiandae nemo reiciendis minima ut dolor eveniet laboriosam nulla nam aperiam blanditiis error itaque ex, molestiae quibusdam sint. Fuga quas quis accusamus. Dolore, aperiam aliquid? Impedit non, error accusantium modi officia quo illum sed perspiciatis ad nemo sit odit temporibus mollitia corporis quam ipsum consectetur ratione accusamus est dolorum laudantium aliquid!",
    image: {
      path: "/static/media/topical-1.827d45ba.jpg",
      name: "Jubliläum-Curiensis",
    },
  },
  {
    id: 2,
    // "title": "Über uns",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam error dolores deleniti. Ratione et consequatur id magnam, assumenda aut facilis illum sit nihil? Hic doloribus reiciendis voluptates ex iusto quasi cum! Explicabo cum pariatur ea architecto numquam dolor, voluptatibus sit ad. Nam incidunt, eius ipsam, cumque voluptatem impedit hic praesentium culpa odit consequuntur, beatae velit aperiam blanditiis a ad veritatis! Esse natus, animi alias corrupti inventore dolorem, optio odio doloremque sapiente aliquam ullam aperiam. Dolorum tempore cumque iusto ipsum adipisci reprehenderit, possimus ab consequuntur eveniet pariatur voluptates ex nemo debitis quas? Obcaecati ad, veritatis aspernatur molestias quaerat cupiditate quas dolore!",
    image: {
      path: "/static/media/topical-1.827d45ba.jpg",
      name: "Jubliläum-Curiensis",
    },
  },
];

export default mockData;
