import React from "react";

import Spub from "../../assets/partner/SPub.png";
import Casutt from "../../assets/partner/casutt.png";
import FCS from "../../assets/partner/fcs.png";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer id="footer" className="bg-light">
      <div className="container">
        <div className="footer-item footer-title" id="footer-impressum-title">
          <h2>Impressum</h2>
        </div>
        <div
          className="footer-item footer-content"
          id="footer-impressum-content">
          <p>AV Curiensis</p>
          <p>Alte Schanfiggerstrasse 7</p>
          <p>7000 Chur</p>
          <a href="mailto:info@avcuriensis.ch">info@avcuriensis.ch</a>
        </div>

        <div className="footer-item footer-title" id="footer-parter-title">
          <h2>Unsere Partner</h2>
        </div>
        <div className="footer-item footer-content" id="footer-spub">
          <a
            href="https://www.sunshinepub.ch/"
            target="_blank"
            rel="noreferrer"
            className="grid-item partner-link spub">
            <img src={Spub} alt="Logo_SPub" />
          </a>
        </div>
        <div className="footer-item footer-content" id="footer-casutt">
          <a
            href="https://casutt-gruppe.ch/"
            target="_blank"
            rel="noreferrer"
            className="grid-item partner-link casutt">
            <img src={Casutt} alt="Logo_Casutt" />
          </a>
        </div>
        <div className="footer-item footer-content" id="footer-fcs">
          <a
            href="https://www.facebook.com/fundacioncesarsaldivar/"
            target="_blank"
            rel="noreferrer"
            className="grid-item partner-link cesar">
            <img src={FCS} alt="Logo_Casutt" />
          </a>
        </div>
        <div id="copy-right" className="footer-item ">
          <p> AV Curiensis Chur &copy; 2020. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
