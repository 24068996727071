import React from "react";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import CircularProgress from "@material-ui/core/CircularProgress";

import Member from "./Member";

import "./Komitee.scss";

const COMMITTEES = loader("../../graphql/user/COMMITTEES.gql");

const Komitee = () => {
  const year = new Date().getFullYear();

  const { loading, error, data } = useQuery(COMMITTEES);
  if (loading)
    return (
      <div className="center">
        <CircularProgress color="inherit" />
      </div>
    );
  if (error) {
    toast.error(error.message, {
      position: "bottom-center",
    });
  }
  if (!data)
    return (
      <div className="center">
        <h2>Keine Daten</h2>
      </div>
    );

  const committees = [
    { rank: "SENIOR" },
    { rank: "COSENIOR" },
    { rank: "AKTUAR" },
    { rank: "QUESTOR" },
    { rank: "FM" },
  ];
  data.committees.map(dc => {
    committees.map((c, i) => {
      if (c.rank === dc.rank) {
        committees[i] = dc;
      }
    });
  });

  return (
    <section id="team" className="team">
      <h1>Unser Komitee</h1>
      <h3>des Herbstsemesters {year}</h3>
      <div className="flex-items">
        {committees.map(m => m.vulgo && <Member key={m.vulgo} data={m} />)}
      </div>
    </section>
  );
};

export default Komitee;
